import React, { Fragment, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Alerts, Breadcrumbs, H3, P } from "../../../AbstractElements";
import ActivityReview from "./ActivityReview";
import ProfileGreeting from "./ProfileGreeting";
import Transaction from "./Transaction";
import YearlyView from "./YearlyView";
import { useSelector } from "react-redux";
import { getCompany, getCompanyRole, getDashboardBottomData, getDashboardBottomDataRole, getDashboardData, getDashboardDataRole, SendEmailVerification } from "../../../CommonUrl";
import { getClientUrlDataToken, postClientUrlWithToken, postMasterToken } from "../../../Utils/restUtils";
import ComplaintDetails from "../../Widgets/General/ComplaintDashboard";
import PaymentCollections from "../../Widgets/General/PaymentCollection";
import SubscriptionDetails from "../../Widgets/General/SubscriptionDashboard";
import TotalCollMeter from "../../Widgets/General/TotalCollMeter";
import MonthCollMeter from "../../Widgets/General/Testimonial";
import Transparentspinner from "../../Common/Loader/Transparentspinner";
import { CollectionsAgent, ConnectionMeter, Connections, MonthlyOverview, PaymentCollection, RevenueMeter } from "../../../Constant";
import OopserrorImageWhite from "../../Common/Oopserror/OopserrorImage";
import OpsErrorBlack from "../../Common/Oopserror/OpsErrorBlack";
import HeaderCard from "../../Common/Component/HeaderCard";
import { toast } from 'react-toastify';

const Default = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)
    const authState = useSelector((state) => state.auth);
    const [dataList, setDataList] = useState([]);
    const [dashboardList, setDashboardList] = useState([]);
    const [active, setActive] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        dashboardData();
        companyData();
        window.history.pushState(null, "", window.location.href);
        const handlePopState = () => {
            window.history.pushState(null, "", window.location.href);
        };
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const dashboardData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole == 1 ? getDashboardBottomData : getDashboardBottomDataRole);
        if (data.status == true) {
            setDataList(data.data);
            setspinnerTrans(false);
        }
        else {
            setspinnerTrans(false);
        }
        const dashboardData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getDashboardData : getDashboardDataRole);
        if (dashboardData.status == true) {
            setDashboardList(dashboardData.data);
            setspinnerTrans(false);
        } else {
            setspinnerTrans(false);
        }
    };

    const companyData = async () => {
        const data = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? getCompany : getCompanyRole);
        if (data.data.status == true) {
            const company_api = data.data.data;
            if (company_api.length > 0) {
                const activeCompany = company_api.filter(item => item.status == 'active');
                setActive(activeCompany[0].name);
            }
            setspinnerTrans(false);
        } else {
            setspinnerTrans(false);
        }
    };

    const navigateToEmail = async () => {
        const response = await postMasterToken(SendEmailVerification, { email: authState.email });
        if (response.status === 200) {
            if (response.data.status === true) {
                toast.success(response.data.message)
                navigate(`${process.env.PUBLIC_URL}/email-otp`);
            } else {
                toast.error(response.data.message)
            }
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            {authState.subStatus?.email_verified === 'no' &&
                <div style={{ paddingTop: '15px' }}>
                    <Alerts attrAlert={{ color: 'danger dark' }}>
                        <P>Verify the Email! <span className="alert-link" style={{ cursor: 'pointer' }} onClick={() => navigateToEmail()}>Click here</span> to verify your Email.</P>
                    </Alerts>
                </div>
            }
            {dataList.default_payment_method === false &&
                <div>
                    <Alerts attrAlert={{ color: 'danger dark' }}>
                        <P><span className="alert-link" style={{ cursor: 'pointer' }} onClick={() => navigate(`${process.env.PUBLIC_URL}/settings/payment`)}>Click here</span> to set the the payment method for different collection type.</P>
                    </Alerts>
                </div>
            }
            <Breadcrumbs mainTitle="Dashboard" title="Dashboard" parent="Dashboard" />
            <Container fluid={true} className="default-page">
                <Row>
                    {active !== '' ? (
                        <ProfileGreeting companyName={active} checks={dataList.check_condition} dataRecurring={dataList.recurring_count} />
                    ) : (
                        <Col lg={5}>
                            <Card className="profile-greeting ">
                                <CardBody>
                                    <OopserrorImageWhite />
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {Object.keys(dashboardList).length > 0 ? (
                        <YearlyView monthChart={dashboardList.collection_chart} />
                    ) : (
                        <Col xl="4" lg="3" md="6">
                            <Card className="yearly-view" >
                                <CardHeader className="pb-0">
                                    <H3>{MonthlyOverview}</H3>
                                </CardHeader>
                                <OpsErrorBlack />
                            </Card>
                        </Col>
                    )}
                    {Object.keys(dashboardList).length > 0 ? (
                        <ActivityReview connections={dashboardList} />
                    ) : (
                        <Col xl="3" md="6" lg="4">
                            <Card className="activity-review" style={{ height: '265px' }}>
                                <CardHeader className="pb-0">
                                    <H3> {Connections} </H3>
                                </CardHeader>
                                <OpsErrorBlack />
                            </Card>
                        </Col>
                    )}
                    {Object.keys(dashboardList).length > 0 ? (
                        <TotalCollMeter collection={dashboardList.collection_meter} />
                    ) : (
                        <Col xl="6">
                            <Row>
                                <Col xl="12" md="12" className="box-col-12">
                                    <Card className="order-widget">
                                        <CardHeader className="pb-0">
                                            <H3> {RevenueMeter} </H3>
                                        </CardHeader>
                                        <CardBody>
                                            <OpsErrorBlack />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {Object.keys(dashboardList).length > 0 ? (
                        <MonthCollMeter collection={dashboardList.collection_this_month_meter} />
                    ) : (
                        <Col xl="6">
                            <Row>
                                <Col xl="12" md="12" className="box-col-12">
                                    <Card className="order-widget">
                                        <CardHeader className="pb-0">
                                            <H3> {ConnectionMeter} </H3>
                                        </CardHeader>
                                        <CardBody>
                                            <OpsErrorBlack />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {Object.keys(dataList).length > 0 ? (
                        <PaymentCollections details={dataList.payment_collection} />
                    ) : (
                        <Col xl="6" className="box-col-6">
                            <Card className="widget-joins widget-arrow">
                                <HeaderCard title={PaymentCollection} setting={true} />
                                <CardBody>
                                    <Row className="gy-4">
                                        <OpsErrorBlack />
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {(authState.userRole === 1 || authState.permissions.includes('show-complaint')) && (
                        Object.keys(dataList).length > 0 ? (
                            <ComplaintDetails data={dataList.complaints} />
                        ) : (
                            <Col xl="6" className="box-col-6">
                                <Card className="widget-joins complainHeight">
                                    <HeaderCard title={'Complaint Details'} setting={true} />
                                    <CardBody>
                                        <Row className="gy-4">
                                            <OpsErrorBlack />
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    )}
                    {(authState.userRole === 1 || authState.permissions.includes('show-subscription')) && (
                        Object.keys(dataList).length > 0 ? (
                            <SubscriptionDetails details={dataList.subscriptions} />
                        ) : (
                            <Col xl="6" className="box-col-6">
                                <Card className="widget-joins">
                                    <HeaderCard title={'Subscription Details'} setting={true} />
                                    <CardBody>
                                        <Row className="gy-4">
                                            <OpsErrorBlack />
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    )}
                    {Object.keys(dashboardList).length > 0 ? (
                        <Transaction collectionAgent={dashboardList.agent_collection} />
                    ) : (
                        <Col xl="6">
                            <Card className="transaction-history">
                                <CardHeader className="pb-0">
                                    <H3>{CollectionsAgent}</H3>
                                </CardHeader>
                                <CardBody>
                                    <div className="dashboardTable">
                                        <OpsErrorBlack />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Container>
            <Outlet />
        </Fragment>
    );
};

export default Default;