import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Media, Label, Input, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { Controller, useForm } from 'react-hook-form';
import { H3, Btn, Breadcrumbs, H4, Image } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, Packages, Package, PackageList, Search, Category, Filter, Export, importFile, Import, importPackge } from '../../../Constant';
import { statusPackage, viewPackage, deletePackage, viewPackageRole, statusPackageRole, postImportPackageApi, publicSampleDownloadpackage, deletePackageRole, postImportPackageApiRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { exportToExcel } from '../../Common/Export';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PopupImport from '../../Common/Popup/popupImport';
import ImportPopTax from '../../Common/Import/ImportPopTax';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const PackagesListContainer = () => {
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const authState = useSelector((state) => state.auth);
    const [packageList, setPackageList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [filteredAddonData, setFilteredAddonData] = useState([]);
    const navigate = useNavigate();
    const [modalImport, setModalImport] = useState(false);
    const handleModalImport = () => {
        setModalImport(!modalImport);
    }
    const fileImport = <span className='billerqColor bolder '>
        <h3>{importFile}</h3>
    </span>


    useEffect(() => {
        if (searchKeyword == '') {
            fetchPackages();
        }
    }, [searchKeyword]);

    const PackageColumns = [
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            width: '50px',

        },
        {
            name: <H4>Image</H4>,
            selector: row => row.Image,
            sortable: true,
            width: '110px'

        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true,
            width: '110px',
            sortFunction: (a, b) => {
                const nameA = a?.Name?.toString().toLowerCase() || '';
                const nameB = b?.Name?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Category</H4>,
            selector: row => row.Category,
            sortable: true,
            center: false,
            width: '100px',
            sortFunction: (a, b) => {
                const nameA = a?.Category?.toString().toLowerCase() || '';
                const nameB = b?.Category?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Connection type</H4>,
            selector: row => row.con_type,
            sortable: true,
            center: false,
            width: '120px',
            
            sortFunction: (a, b) => {
                const nameA = a?.con_type?.toString().toLowerCase() || '';
                const nameB = b?.con_type?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Tax %</H4>,
            selector: row => parseFloat(row.Tax.replace('%', '').trim()),
            sortable: true,
            cell: row => row.Tax,
            width: '100px'
        },
        {
            name: <H4>Price</H4>,
            selector: row => parseFloat(
                row.Price
                    .replace(authState.currency, '')
                    .replace(/,/g, '')
                    .trim()
            ),
            sortable: true,
            cell: row => row.Price,
            width: '120px'
        },
        {
            name: <H4>Sub Total</H4>,
            selector: row => parseFloat(
                row.SubTotal
                    .replace(authState.currency, '')
                    .replace(/,/g, '')
                    .trim()
            ),
            cell: row => row.SubTotal,
            sortable: true,
            width: '120px'
        },
        {
            name: <H4>Duration Days</H4>,
            selector: row => row.Duration,
            sortable: true,
            width: '100px'
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
        },
    ];

    const handleSwitchToggle = async (event, packageId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { package_id: packageId, status: 'inactive' }
        } else {
            reqSwitch = { package_id: packageId, status: 'active' }
        }

        const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusPackage : statusPackageRole, reqSwitch);
        if (statusChange.status === 200) {
            if (statusChange.data.status === true) {
                toast.success(statusChange.data.message);
            } else {
                toast.error(statusChange.data.message);
            }
        } else if (statusChange.status >= 400 && statusChange.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (statusChange.status >= 500 && statusChange.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/Services/packages-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deletePackageList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { package_id: row.id }
                    let packageDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deletePackage : deletePackageRole, deleteRes);
                    if (packageDelete.status === 200) {
                        if (packageDelete.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            fetchPackages()
                            setLoading(false);
                        }
                        else {
                            setLoading(false);
                            toast.error(packageDelete.data.message);
                        }
                    } else if (packageDelete.status >= 400 && packageDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (packageDelete.status >= 500 && packageDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
                setLoading(false);
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {(authState.userRole === 1 || authState.permissions.includes('edit-package')) &&
                        <DropdownItem style={toggleStyle} onClick={() => handleEditClick()}>Edit</DropdownItem>
                    }
                    {(authState.userRole === 1 || authState.permissions.includes('remove-package')) &&
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deletePackageList(row.id)}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        fetchPackages();
    };

    const fetchPackages = async (categoryId) => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPackage : viewPackageRole, { category: categoryId });
        if (data.status === true) {
            if (Array.isArray(data.data)) {
                const filteredPackages = data.data.filter((item) => {
                    const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                    const categoryMatches = item.category_name.toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch || categoryMatches;
                });
                setFilteredAddonData(filteredPackages);
                setPackageList(filteredPackages.map((e, index) => {
                    return {
                        Si: index + 1,
                        Image: <Image attrImage={{ src: e.package_image, alt: '' }} />,
                        Name: e.name,
                        Category: e.category_name,
                        Tax: e.total_tax_percentage + '%',
                        Price: authState.currency + " " + e.price,
                        SubTotal: authState.currency + " " + e.sub_total,
                        Duration: e.duration,
                        con_type: e.connection_type,
                        id: e.id,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                    }
                }))

                setLoading(false);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const exportPackageExcel = () => {
        setLoading(true);
        const exportData = filteredAddonData.map(({ name, category_name, connection_type, total_tax_percentage, price, sub_total, duration, status }) => ({ Name: name, Category: category_name, Connection_Type: connection_type, Tax_Percentage: total_tax_percentage + '%', Price: price, Sub_Total: sub_total, Duration: duration, Status: status }));
        exportToExcel(exportData, "package_data");
        setLoading(false);
    };

    const exportPackagePdf = () => {
        setLoading(true);
        const filteredData = filteredAddonData.map(({ name, category_name, connection_type, total_tax_percentage, price, sub_total, duration, status }, index) => {
            return { Sl: index + 1, name, category_name, connection_type, Tax_Percentage: total_tax_percentage + '%', Price: price, sub_total, duration, status };
        });
        const doc = new jsPDF();
        const columns = ["Sl", "Name", "Category", "Connection Type", "Tax%", "Price", "Sub Total", "Duration days", "Status"];
        const rows = filteredData.map(entry => Object.values(entry));
        doc.autoTable({
            head: [columns],
            body: rows
        });
        doc.save("package_data.pdf");
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Packages} parent="Services" title={Package} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{PackageList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            {(authState.userRole === 1 || authState.permissions.includes('store-package')) &&
                                <Link to={`${process.env.PUBLIC_URL}/Services/packages-add`} className="btn btn-primary me-1" >{Add}</Link>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('import-package')) &&
                                <Btn attrBtn={{ color: 'primary m-l-5', onClick: () => handleModalImport() }} >{Import} <i className="icofont icofont-arrow-down"></i></Btn>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('package-export')) &&
                                <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary button-spacing">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportPackageExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportPackagePdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} id="validationCustom01" type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>

                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="table-responsive ">
                        <div id='basic-1_wrapper' className='dataTables_wrapper no-footer'>
                            <DataTable
                                className='display dataTable no-footer'
                                noHeader
                                pagination
                                defaultSortAsc
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                fixedHeader
                                columns={[
                                    {
                                        name: <H4>Sl</H4>,
                                        selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                        width: '60px',
                                        sortable: false, // Disable sorting for SI
                                    },
                                    ...PackageColumns // All other columns remain the same
                                ]}
                                data={packageList}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
            <PopupImport isOpen={modalImport} title={fileImport} toggler={handleModalImport} >
                <ImportPopTax
                    setModal={setModalImport}
                    sampleImportApi={publicSampleDownloadpackage}
                    postImportApi={authState.userRole === 1 ? postImportPackageApi : postImportPackageApiRole}
                    imporTitle={importPackge}
                    tablerenderFunc={fetchPackages}
                />
            </PopupImport>

        </Fragment>
    );


};

export default PackagesListContainer;