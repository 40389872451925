import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  Amount,
  Customer,
  Description,
  PaymentMethod,
  Reference,
  Save,
  Wallet,
  addwallet,
  AddDeduct
} from "../../../Constant";
import { AddWallet, AddWalletRole, getCustomers, getCustomersRole, paymentMethod, paymentMethodRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import {
  getClientUrlDataToken,
  postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  customer: yup.mixed().required().label('Customer'),
  addDeduct: yup.mixed().required().label('Add/Deduct'),
  amount: yup.string().required().label('Amount'),
  paymentmethod: yup.mixed().required().label('Payment Method'),
})

const WalletForm = () => {
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const authState = useSelector((state) => state.auth);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Payment, setPayment] = useState([]);

  const type = [
    { value: "add", label: "Add" },
    { value: "deduct", label: "Deduct" },

  ];

  const navigate = useNavigate();

  useEffect(() => {
    areaData();
  }, []);


  useEffect(() => {
    const inputElement = document.getElementById("amount");

    const preventScrollEvent = (event) => {
      event.preventDefault();
    };

    inputElement.addEventListener('wheel', preventScrollEvent);

    return () => {
      inputElement.removeEventListener('wheel', preventScrollEvent);
    };

  }, []);


  const areaData = async () => {
    let Customers_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
    if (Customers_api.status === true) {
      const customermaped = Customers_api.data.map((items) => ({ value: items.id, label: items.name }))
      setCustomers(customermaped)
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }

    let payment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentMethod : paymentMethodRole);
    if (payment.status === true) {
      const set_payment = payment.data.map((itemss) => ({ value: itemss.id, label: itemss.name }))
      setPayment(set_payment)
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const postRequest = {
      customer_id: data.customer.value,
      transaction_type: data.addDeduct.value,
      payment_method: data.paymentmethod.value,
      amount: data.amount,
      description: data.address,
      reference: data.message,

    };

    const addCustomerResponse = await postClientUrlWithToken(
      authState.apiUrl,
      authState.userRole === 1 ? AddWallet : AddWalletRole,
      postRequest
    );
    if (addCustomerResponse.status === 200) {
      if (addCustomerResponse.data.status === true) {
        toast.success(addCustomerResponse.data.message)
        navigate(`${process.env.PUBLIC_URL}/customers/wallet`);
      } else {
        toast.error(addCustomerResponse.data.message)

      }
    } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }

    setLoading(false);
  };
  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs mainTitle={Wallet} parent="Customers" title={Wallet} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{addwallet}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Customer} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={customers}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.customer?.message}</p>

              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {AddDeduct}  <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="addDeduct"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={type}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.addDeduct?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6" >
                <Label className="form-label" for="validationCustom02">
                  {Amount} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control diableScroll"
                  id="amount"
                  type="number"
                  min={0}
                  {...register("amount", { required: true })}
                />
                <p className="text-danger">{errors.amount?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {PaymentMethod} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="paymentmethod"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={Payment}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.paymentmethod?.message}</p>
              </Col>

            </Row>
            <Row className="g-3 pt-3">

            </Row>
            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Description}</Label>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" />
                  )}
                />
              </Col>
            </Row>
            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Reference}</Label>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" />
                  )}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default WalletForm;
