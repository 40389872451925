import React, { useEffect, useState } from 'react';
import { getArea, getAreaRole } from '../../CommonUrl';
import { getClientUrlDataToken } from '../../Utils/restUtils';

const AreaDropdown = ({ setValue, register, authState, registerName, selectedAreaId }) => {
    const [apiArea, setArea] = useState([]);

    useEffect(() => {
        userData();
    }, []);

    useEffect(() => {
        if (selectedAreaId) {
            const selectedArea = apiArea.find((area) => area.id === parseInt(selectedAreaId));
            if (selectedArea) {
                setValue('area', selectedArea.id.toString());
            }
        }
    }, [selectedAreaId, apiArea, setValue]);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const userData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setArea(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    return (
        <select className="form-select" {...register(registerName)}>
            <option value="">Select Area</option>
            {apiArea.map((values, index) => (
                <option key={index} value={values.id}>{values.name}</option>
            ))}
        </select>
    );
};

export default AreaDropdown;
