import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useForm } from 'react-hook-form';
import { Add, Complaint, ComplaintList, Search, Area, Filter, ProblemType, Export } from '../../Constant';
import { getComplaint, getComplaintRole, getstatscountcomplaints, getArea, getProblemType, getstatscountcomplaintsRole, getAreaRole, getProblemTypeRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { conditionalRowComplaints } from '../Common/Rowstyle/Rowstyle';
import Widgets from '../Common/Progress/Widgets';
import TransparentSpinner from '../Common/Loader/Transparentspinner'

const ComplaintsListContainer = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("status");
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [complaintLists, setComplaintList] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        area: '',
        problem_type: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [apiArea, setapiArea] = useState([]);
    const [apiProblem, setapiProblem] = useState([]);
    const [staticwidgets, setstaticwidgets] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: '', order: '' });

    const complaintColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            width: '50px',
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '20px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '90px',
        },
        {
            name: <H4>Subscriber id</H4>,
            id: 'subscriber_id',
            selector: row => row.Sub,
            sortable: true,
            center: true,
            wrap: true,
            width: '120px',
            cell: row => (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.CustomerId}`}>
                    {row.Sub}
                </Link> : row.Sub,
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            width: '105px',
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                    customer>
                    {row.Status.charAt(0).toUpperCase() + row.Status.slice(1)}
                </span>
            ),
        },
        {
            name: <H4>Name</H4>,
            id: 'name',
            selector: row => row.Name,
            sortable: true,
            width: '105px',
            wrap: true
        },
        {
            name: <H4>Area</H4>,
            id: 'area',
            selector: row => row.Area,
            sortable: true,
            width: '105px',
        },
        {
            name: <H4>Mobile No</H4>,
            id: 'phone',
            selector: row => "+" + row.Mobile,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Problem type</H4>,
            id: 'problem_type',
            selector: row => row.Problem,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Updated by</H4>,
            id: 'updated_by',
            selector: row => row.Update,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Date</H4>,
            id: 'updated_at',
            selector: row => row.Date,
            sortable: true,
            wrap: true
        },
    ];

    useEffect(() => {
        fetchComplaint();
        getwidgetsdata();
        areaData();
        problemData()
    }, []);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortConfig.column === column.id) {
            newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: column.id, order: newOrder });
        fetchComplaint(currentPage, perPage, filters.search, filters.area, filters.problem_type, column.id, newOrder);
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const getwidgetsdata = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getstatscountcomplaints : getstatscountcomplaintsRole);
        if (data.status === true) {
            setstaticwidgets(data.data);
        } else {
            handleApiError(data.status);
        }
    }

    const areaData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const problemData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getProblemType : getProblemTypeRole);
        if (data.status === true) {
            setapiProblem(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleShowClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/complaints/complaint-show?id=${row.id}`;
            navigate(editFormURL);
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => handleShowClick()}>Show</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const handlePageChange = page => {
        fetchComplaint(page, perPage, filters.search, filters.area, filters.problem_type, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchComplaint(page, newPerPage, filters.search, filters.area, filters.problem_type, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const onSubmit = async (data) => {
        const search = data.search;
        const area = data.area;
        const problem_type = data.problem;
        setFilters({
            search,
            area,
            problem_type
        });
        fetchComplaint(1, perPage, search, area, problem_type);
    };

    const fetchComplaint = async (page, size = perPage, searchKey = '', areaId = '', problemTypeId = '', sortBy = '', sortOrder = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getComplaint +
            `?page=${page}&page_length=${size}&search=${searchKey}&area_id=${areaId}&problem_type=${problemTypeId}&status=${param1 ? param1 : ''}&sort_by=${sortBy}&sort_order=${sortOrder}` :
            getComplaintRole + `?page=${page}&page_length=${size}&search=${searchKey}&area_id=${areaId}&problem_type=${problemTypeId}&status=${param1 ? param1 : ''}&sort_by=${sortBy}&sort_order=${sortOrder}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            setComplaintList(response.data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    Sub: <span>{e.subscriber_id}</span>,
                    Name: e.customer_name,
                    Area: e.area_name,
                    Mobile: e.phone,
                    Problem: e.problem_type,
                    Update: e.updated_by,
                    Date: e.start_date,
                    Status: e.status,
                    id: e.id,
                    CustomerId: e.customer_id
                }
            }))
            setTotalRows(response.data.total);
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    const exportComplaintExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getComplaint +
            `?export=complaint_data&search=${filters?.search ?? ''}&area_id=${filters?.area}&problem_type=${filters?.problem_type}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getComplaintRole + `?export=complaint_data&search=${filters?.search ?? ''}&area_id=${filters?.area}&problem_type=${filters?.problem_type}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.data.map(({ subscriber_id, status, customer_name, area_name, phone, problem_type, updated_by, start_date }) => ({ Subscriber_Id: subscriber_id, Status: status, Name: customer_name, Area: area_name, Mobile: phone, Problem_Type: problem_type, Updated_By: updated_by, Date: start_date }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Complaint Data');
            XLSX.writeFile(wb, 'complaint_data.xlsx');
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    const exportComplaintPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getComplaint +
            `?export=complaint_data&search=${filters?.search ?? ''}&area_id=${filters?.area}&problem_type=${filters?.problem_type}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getComplaintRole+`?export=complaint_data&search=${filters?.search ?? ''}&area_id=${filters?.area}&problem_type=${filters?.problem_type}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.data.map(({ subscriber_id, status, customer_name, area_name, phone, problem_type, updated_by, start_date }, index) => ({ Sl: index + 1, subscriber_id, status, customer_name, area_name, phone, problem_type, updated_by, start_date }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber Id", "Status", "Name", "Area", "Mobile", "Problem Type", "Updated By", "Date"];
            const rows = dataToExport.map(({ subscriber_id, status, customer_name, area_name, phone, problem_type, updated_by, start_date }, index) => [index + 1, subscriber_id, status, customer_name, area_name, phone, problem_type, updated_by, start_date]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("complaint_data.pdf");
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <TransparentSpinner />}
            <Breadcrumbs mainTitle={Complaint} parent="Complaint" title={Complaint} />
            <Widgets details={staticwidgets} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{ComplaintList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            {(authState.userRole === 1 || authState.permissions.includes("store-complaint")) &&
                                <Link to={`${process.env.PUBLIC_URL}/complaints-add`} className="btn btn-primary me-2">{Add}</Link>
                            }
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportComplaintExcel()} ><i className="fa fa-file-excel-o me-2"></i>Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportComplaintPdf()}><i className="fa fa-file-pdf-o me-2"></i>Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>

                </CardHeader>

                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                                <Col sm="12" md='5' lg='2'>
                                    <input className="form-control" placeholder={Search} type="text"
                                        {...register('search')} />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select"{...register('area')}>
                                        <option value="">{Area}</option>
                                        {apiArea.map((values, index) => (
                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select"  {...register('problem')}>
                                        <option value="">{ProblemType}</option>
                                        {apiProblem.map((v, index) => (
                                            <option key={index} value={v.type}>{v.type}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={complaintColumns}
                            data={complaintLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRowComplaints}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ComplaintsListContainer;