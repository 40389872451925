import React, { useEffect, useState } from 'react';
import { getUsers, getUsersRole } from '../../CommonUrl';
import { getClientUrlDataToken } from '../../Utils/restUtils';

const UserDropdown = ({ setValue, register, authState, registerName, selectedUserId }) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        userData();
    }, []);

    useEffect(() => {
        if (selectedUserId) {
            const selectedUser = users.find((user) => user.user_id === parseInt(selectedUserId));
            if (selectedUser) {
                setValue(registerName, selectedUser.user_id.toString());
            }
        }
    }, [selectedUserId, users, setValue]);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const userData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getUsers : getUsersRole);
        if (data.status === true) {
            setUsers(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    return (
        <select className="form-select" {...register(registerName)}>
            <option value="">Select User</option>
            {users.map((user) => (
                <option key={user.user_id} value={user.user_id}>
                    {user.user_name}
                </option>
            ))}
        </select>
    );
};

export default UserDropdown;
