import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpenseEdit, Expenses, Save, Vendor, Header, Amount, ExpenseType, Notes, AttachBills, BillDate, DueDate, BillNo, OrderNo, PaymentDate, } from "../../Constant";
import { editExpense, selectVendor, updateExpense, editExpenseRole, selectVendorRole, viewPayment, viewPaymentRole, selectHeadersRole, selectHeaders, updateExpenseRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../CommonElements/TypeAhead";
import DatePicker from 'react-datepicker';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import Transparentspinner from "../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    vendor: yup.mixed().required().label('Vendor'),
    header: yup.mixed().required().label('Header'),
    amount: yup.string().required().label('Amount'),
    type: yup.mixed().required().label('Expense Type'),
});

const ExpensesEdit = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [image, setImage] = useState(null);
    const [apiImage, setapiImage] = useState(null)
    const [loading, setLoading] = useState(false);
    const [selectedExpenseType, setSelectedExpenseType] = useState('');
    const [headerModal, setHeaderModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [vendorOption, setVendorOption] = useState([]);
    const [headerOption, setHeaderOption] = useState([]);
    const [dueDate, setDueDate] = useState('');
    const [billDate, setBillDate] = useState('');
    const [payDate, setPayDate] = useState('');
    const navigate = useNavigate();
    const form = new FormData()

    const handleImageChange = (event, field) => {
        const selectedFile = event.target.files[0];
        setImage(selectedFile);
        field.onChange(event);
    }

    const expenseTypeOptions = [
        { value: 'bills', label: "Bills" },
        { value: "payments", label: "Payments" }
    ]

    const handleExpenseTypeChange = (value) => {
        setSelectedExpenseType(value);
    };

    const handleModal = (id) => {
        setModal(!modal);
    }
    const handleHeaderModal = (id) => {
        setHeaderModal(!headerModal);
    }

    const handleChangeDueDate = date => {
        setDueDate(date);
    };
    const handleChangeBillDate = date => {
        setBillDate(date);
    };
    const handleChangePayDate = date => {
        setPayDate(date);
    };

    useEffect(() => {
        expenseData();
    }, []);

    const expenseData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? editExpense + `?expense_id=${param1}` : editExpenseRole + `?expense_id=${param1}`);
        if (data.status === true) {
            const single_Api = data.data;
            if (single_Api.type === 'bills') {
                setSelectedExpenseType('bills');
            }
            if (single_Api.type === 'payments') {
                setSelectedExpenseType('payments');
            }
            if (single_Api) {
                setValue('vendor_id', { value: single_Api.vendor_id, label: single_Api.vendor_name });
                setValue('header_id', { value: single_Api.header_id, label: single_Api.header_name });
                setValue('amount', single_Api.amount);
                setValue('type', { value: single_Api.type.value, label: single_Api.type.label });
                setValue('payment_method', { value: single_Api.payment_method_id, label: single_Api.payment_method_name });
                setValue('payment_date', single_Api.payment_date);
                setValue('payment_no', single_Api.payment_no);
                setValue('bill_date', single_Api.bill_date);
                setValue('bill_no', single_Api.bill_no);
                setValue('order_no', single_Api.order_no);
                setValue('due_date', single_Api.due_date);
                setapiImage(single_Api.expense_file)
                setValue('description', single_Api.description);

                const due_dates = data.data.due_date;
                if (due_dates !== "0000-00-00") {
                    setDueDate(moment(due_dates, 'YYYY-MM-DD').toDate());
                }

                const bill_dates = data.data.bill_date;
                if (bill_dates) {
                    setBillDate(moment(bill_dates, 'YYYY-MM-DD').toDate());
                }
                const pay_dates = data.data.payment_date;
                if (pay_dates) {
                    setPayDate(moment(pay_dates, 'YYYY-MM-DD').toDate());
                }

                const type = expenseTypeOptions.find((e) => e.value === single_Api.type);
                setValue("type", { value: single_Api.type, label: single_Api.type });

                let headerData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? selectHeaders : selectHeadersRole);
                if (headerData.status === true) {
                    const optionList = headerData.data.map((data) => ({ value: data.id, label: data.title }))
                    setHeaderOption(optionList);
                    const same_id = optionList.find((set_id) => set_id.value === single_Api.header_id);
                    setValue("header", { value: same_id?.value, label: same_id?.label })

                } else if (headerData.status >= 400 && headerData.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (headerData.status >= 500 && headerData.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
                else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }

                let dataVendor = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? selectVendor : selectVendorRole);
                if (dataVendor.status === true) {
                    const optionsList = dataVendor.data.map((data) => ({ value: data.id, label: data.title }));
                    setVendorOption(optionsList);
                    const equal_id = optionsList.find((set_id) => set_id.value === single_Api.vendor_id);
                    setValue("vendor", { value: equal_id?.value, label: equal_id?.label })
                } else if (dataVendor.status >= 400 && dataVendor.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (dataVendor.status >= 500 && dataVendor.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
                else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }

                let dataPayment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPayment : viewPaymentRole);
                if (dataPayment.status === true) {
                    const optionsList = dataPayment.data.map((data) => ({ value: data.id, label: data.name }));
                    const equal_id = optionsList.find((set_id) => set_id.value === single_Api.payment_method);
                    setValue("payment_method", { value: equal_id?.value, label: equal_id?.label })
                } else if (dataPayment.status >= 400 && dataPayment.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (dataPayment.status >= 500 && dataPayment.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
                else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);
        const formattedBillDate = billDate ? moment(billDate).format("YYYY-MM-DD") : "";
        const formattedDueDate = dueDate ? moment(dueDate).format("YYYY-MM-DD") : "";
        const formattedPayDate = payDate ? moment(payDate).format("YYYY-MM-DD") : "";
        form.append("expense_id", param1);
        form.append("vendor_id", data.vendor.value)
        form.append("header_id", data.header.value)
        form.append("amount", data.amount)
        form.append("type", data.type.value)
        form.append("payment_date", formattedPayDate)
        form.append("payment_no", data?.payment_no ?? "")
        form.append("bill_date", formattedBillDate)
        form.append("bill_no", data.bill_no)
        form.append("due_date", formattedDueDate)
        form.append("order_no", data?.order_no ?? "")
        form.append("description", data?.description ?? '');
        if (data.file && data.file[0]) {
            form.append("file", data.file[0]);
        }
        setLoading(true);
        const editPackageResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updateExpense : updateExpenseRole, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });

        if (editPackageResponse.status === 200) {
            if (editPackageResponse.data.status === true) {
                toast.success(editPackageResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Expenses/Expenses`);
            } else {
                toast.error(editPackageResponse.data.message)
                setLoading(false);
            }
        } else if (editPackageResponse.status >= 400 && editPackageResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (editPackageResponse.status >= 500 && editPackageResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Expenses} parent="Expenses" title={ExpenseEdit} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{ExpenseEdit}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-2">
                            <Col md="6">
                                <Label className="form-label" >{Vendor} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="vendor"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={vendorOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleExpenseTypeChange(value.value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.vendor?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >
                                    {Header} <span className="requireStar">*</span>
                                </Label>
                                <Controller
                                    name="header"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={headerOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleExpenseTypeChange(value.value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >
                                    {Amount} <span className="requireStar">*</span>
                                </Label>
                                <input
                                    name="amount"
                                    className="form-control"
                                    id="amount"
                                    type="number"
                                    min="0"
                                    {...register("amount", { required: true })}
                                />
                                <p className="text-danger">{errors.amount?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >
                                    {ExpenseType} <span className="requireStar">*</span>
                                </Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={expenseTypeOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleExpenseTypeChange(value.value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                        </Row>
                        {(selectedExpenseType === 'bills') && (
                            <Row className="g-3 pt-3">
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label className="form-label">{BillDate} </Label>
                                        <Col xl='12' md="12" sm='12'>
                                            <DatePicker
                                                id="due"
                                                style={{ padding: '12px 100px' }}
                                                className="form-control "
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="dd/mm/yyyy"
                                                onChange={handleChangeBillDate}
                                                selected={billDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                required
                                            />
                                        </Col>

                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label className="form-label" >{DueDate}</Label>
                                        <Col xl='12' md="12" sm='12'>
                                            <DatePicker
                                                id="due"
                                                style={{ padding: '12px 100px' }}
                                                className="form-control "
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="dd/mm/yyyy"
                                                onChange={handleChangeDueDate}
                                                selected={dueDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                required
                                            />
                                        </Col>
                                        {/* <p className="text-danger">{errors.invoice_date?.message}</p> */}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <Label className="form-label" >{BillNo}</Label>
                                    <input name="bill_no" className="form-control" id="billNo" type="number"
                                        {...register("bill_no")}
                                    />
                                </Col>
                                <Col md="6">
                                    <Label className="form-label" >{OrderNo}</Label>
                                    <input name="order_no" className="form-control" id="orderNo" type="number"
                                        {...register("order_no")}
                                    />
                                </Col>
                            </Row>
                        )}
                        {(selectedExpenseType === 'payments') && (
                            <Row className="g-3 pt-3">
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label className="form-label">{PaymentDate}</Label>
                                        <Col xl='12' md="12" sm='12'>
                                            <DatePicker
                                                id="pay"
                                                style={{ padding: '12px 100px' }}
                                                className="form-control "
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="dd/mm/yyyy"
                                                onChange={handleChangePayDate}
                                                selected={payDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                required
                                            />
                                        </Col>
                                        {/* <p className="text-danger">{errors.invoice_date?.message}</p> */}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <Label className="form-label" >
                                        PaymentNo
                                    </Label>
                                    <input
                                        name="payment_no"
                                        className="form-control"
                                        id="paymentNo"
                                        type="number"
                                        {...register("payment_no")}
                                    />
                                </Col>
                            </Row>

                        )}
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label">{AttachBills}</Label>
                                <Controller
                                    name="file"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Col md="8">
                                                <input
                                                    {...register("file")}
                                                    className="form-control fileInputStyle"
                                                    type="file"
                                                    onChange={(event) => handleImageChange(event, field)}
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                            </Col>
                                            <Col md='4'>
                                                <span className="imagestyle">
                                                    {image == null ? apiImage && <img src={apiImage} alt="file" width="40px" height="40px" /> : <img src={URL.createObjectURL(image)} alt="image" width="40px" height="40px" />}
                                                </span>

                                            </Col>
                                        </div>
                                    )}
                                />
                                <span className="text-danger">{errors.logo && "logo is required"}</span>
                            </Col>

                        </Row>

                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Notes}</Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea className="form-control" {...field} rows="3" maxLength={300} />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup></FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>

        </Fragment >
    )
}
export default ExpensesEdit;