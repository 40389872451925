import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Media, Label, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Form, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { bankAccount, bankAccountList, Add, Export, Search, Filter } from '../../../Constant';
import { useSelector } from 'react-redux';
import { deleteAccount, deleteAccountRole, statusAccount, statusAccountRole, viewAccount, viewAccountRole } from '../../../CommonUrl';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const AccountContainer = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const authState = useSelector((state) => state.auth);
    const [accountList, setAccountList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };

    const AccountColumns = [
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            width: '170px',
            wrap: true
        },
        {
            name: <H4>Account No.</H4>,
            selector: row => row.Number,
            sortable: true,
            width: '130px',
            wrap: true,
        },
        {
            name: <H4>Opening Balance</H4>,
            selector: row => parseFloat(
                row.Balance
                    .replace(authState.currency, '')
                    .replace(/,/g, '')
                    .trim()
            ),
            cell: row => row.Balance,
            sortable: true,
            width: '150px',
            center: true
        },
        {
            name: <H4>Current Balance</H4>,
            selector: row => parseFloat(
                row.Current_balance
                    .replace(authState.currency, '')
                    .replace(/,/g, '')
                    .trim()
            ),
            cell: row => row.Current_balance,
            sortable: true,
            width: '150px',
            center: true
        },
        {
            name: <H4>Show On Invoice</H4>,
            selector: row => row.DefStatus,
            sortable: true,
            center: true
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            width: '150px',
            center: true
        },
        {
            name: <H4>Action</H4>,
            selector: row => row.action,
            sortable: true,
            center: true
        },
    ];

    useEffect(() => {
        if (searchKeyword == '') {
            setLoading(true);
            accountData();
        }
    }, [searchKeyword]);

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        accountData();
    };
    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/banking/account-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const accountData = async () => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAccount : viewAccountRole);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            if (Array.isArray(data.data)) {
                const filteredAccount = data.data.filter((item) => {
                    const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                    const numberMatch = item.account_number && item.account_number.toString().includes(searchKeyword);
                    const balanceMatch = item.opening_balance && item.opening_balance.toString().includes(searchKeyword);
                    return nameMatch || numberMatch || balanceMatch;
                });
                setAccountList(filteredAccount.map((e, index) => {
                    return {
                        Si: index + 1,
                        Name: e.name,
                        Number: e.account_number,
                        Balance: authState.currency + ' ' + e.opening_balance,
                        Current_balance: authState.currency + ' ' + e.current_balance,
                        DefStatus: e.default,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                        action:
                            <div>
                                <span>
                                    {(authState.userRole === 1 || authState.permissions.includes('edit-account')) &&
                                        <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                    }
                                </span>
                                <span>
                                    {(authState.userRole === 1 || authState.permissions.includes('delete-account')) &&
                                        <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteAccountList(e.id) } }}>Delete</Btn>
                                    }
                                </span>
                            </div >
                    }
                }));
            }
            setLoading(false);
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };


    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { account_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { account_id: itemId, status: 'active' }
        }
        const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusAccount : statusAccountRole, reqSwitch);
        if (statusChange.status === 200) {
            if (statusChange.data.status === true) {
                toast.success(statusChange.data.message);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
        } else if (statusChange.status >= 400 && statusChange.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (statusChange.status >= 500 && statusChange.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const deleteAccountList = (userId) => {
        SweetAlert.fire({
            title: 'Confirm deletion?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true)
            if (result.value) {
                const deleteRes = { account_id: userId }
                const accountDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteAccount : deleteAccountRole, deleteRes);
                if (accountDelete.status == 200) {
                    if (accountDelete.data.status == true) {
                        SweetAlert.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        accountData();
                    } else {
                        toast.error(accountDelete.data.message);
                        setLoading(false)
                    }
                } else if (accountDelete.status >= 400 && accountDelete.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (accountDelete.status >= 500 && accountDelete.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }
            }
            setLoading(false)
        })
    }

    const exportAccountExcel = async () => {
        setLoading(true);
        let dataToExport = [];
        if (searchKeyword.trim() !== '') {
            dataToExport = accountList.map(({ Name, Number, Balance, Current_balance, Status }) => ({ Name: Name, Number: Number, Opening_Balance: Balance, Current_balance: Current_balance, Status: Status === 'active' ? 'Active' : 'Inactive' }));
        } else {
            const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAccount + `?export=account_data` : viewAccountRole + `?export=account_data`);
            if (response.status === true) {
                dataToExport = response.data.map(({ name, account_number, opening_balance, current_balance, status }) => ({ Name: name, Number: account_number, Opening_Balance: opening_balance, Current_Balance: current_balance, Status: status }));
            } else if (response.status >= 400 && response.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (response.status >= 500 && response.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
        }
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Account Data');
        XLSX.writeFile(wb, 'account_data.xlsx');
        setLoading(false);
    };

    const exportAccountPdf = async () => {
        setLoading(true);
        let dataToExport = [];
        if (searchKeyword.trim() !== '') {
            dataToExport = accountList.map(({ Si, Name, Number, Balance, Status }) => [Si, Name, Number, Balance, Status === 'active' ? 'Active' : 'Inactive']);
        } else {
            const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAccount + `?export=account_data` : viewAccountRole + `?export=account_data`);
            if (response.status === true) {
                dataToExport = response.data.map(({ name, account_number, opening_balance, status }, index) => [index + 1, name, account_number, opening_balance, status]);
            } else if (response.status >= 400 && response.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (response.status >= 500 && response.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
        }

        const doc = new jsPDF();
        const columns = ["Sl", "Name", "Number", "CurrentBalance", "Status"];
        doc.autoTable({
            head: [columns],
            body: dataToExport
        });
        doc.save("account_data.pdf");

        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={bankAccount} parent="Banking" title={bankAccount} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{bankAccountList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            {(authState.userRole === 1 || authState.permissions.includes('store-account')) &&
                                <Link to={`${process.env.PUBLIC_URL}/banking/account-add`} className="btn btn-primary me-2" >{Add}</Link>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('export-account')) &&
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportAccountExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportAccountPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange} />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={accountList}
                            columns={[
                                {
                                    name: <H4>Sl</H4>,
                                    selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                    width: '60px',
                                    sortable: false, // Disable sorting for SI
                                },
                                ...AccountColumns // All other columns remain the same
                            ]}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );

}

export default AccountContainer;
