import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../../Utils/restUtils";
import { CompanyAdd, getCurrency } from "../../../../CommonUrl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  DefaultCurrency,
  DefaultLang,
  Finish,
} from "../../../../Constant";
import Typeahead from "../../../../CommonElements/TypeAhead/index";
import { toast } from "react-toastify";
import Transparentspinner from "../../../Common/Loader/Transparentspinner";
import HandleHomePageError from "../../../Common/ErrorImport/SignError";
import moment from "moment";
import HandleApiError from "../../../Common/ErrorImport/ErrorImport";


const Formstep4 = ({ setFormdata, formdata }) => {
  const [loading, setloading] = useState(false)

  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const form = new FormData();
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const languageOptions = [
    { value: "english", label: "ENGLISH (GB)" },
  ];

  useEffect(() => {
    currencyApi();
  }, []);

  const { handleSubmit, control, formState: { errors } } = useForm({});

  const currencyApi = async () => {
    const data = await getClientUrlDataToken(authState.apiUrl, getCurrency);
    if (data.status === true) {
      const transformedData = data.data.map((item) => ({
        label: item.name,
        value: item.code
      }));
      setCurrencyOptions(transformedData);
    } else {
      HandleApiError(response.status)
    }
  };

  const onSubmit = async (data) => {
    const countryCode = formdata.countryCode;
    const mobile = formdata.phone;
    const formatFinancialyear = moment(formdata.financial).format("DD-MM-YYYY");
    if (mobile && countryCode) {
      setloading(true);
      const phoneno = mobile.slice(countryCode.length)
      setFormdata((prev) => ({ ...prev, ...data }));
      form.append("name", formdata.name);
      form.append("email", formdata.email);
      form.append("phone", phoneno);
      form.append("country_code", countryCode);
      form.append("address", formdata?.address || "");
      form.append("tax_in", formdata?.taxin || "");
      if (formdata.logo) {
        form.append("logo", formdata.logo);
      }
      form.append("financial_year", formatFinancialyear);
      form.append("time_zone", formdata.timezone?.value || "");
      form.append("date_format", formdata.dateFormate?.value || "");
      form.append("separator", formdata.separator?.value || "");
      form.append("position", formdata.position?.value || "");
      form.append("decimal", formdata.decimal?.value || "");
      form.append("item_name", formdata.ItemName?.value || "");
      form.append("price_name", formdata.PriceName?.value || "");
      form.append("quantity_name", formdata.QuantityName?.value || "");
      form.append("invoice_prefix", formdata.invprefix);
      form.append("invoice_suffix", formdata.invsuffix);
      form.append("due_date", formdata?.duedate?.value || "");
      if (formdata.icon) {
        form.append("icon", formdata.icon);
      };
      form.append("currency", data.currency?.value || "");
      form.append("language", data.language?.value || "");

      const addCompanyResponse = await postClientUrlWithToken(
        authState.apiUrl, CompanyAdd, form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (addCompanyResponse.status === 200) {
        if (addCompanyResponse.data.status === true && authState.subscription.status === 'none') {
          toast.success(addCompanyResponse.data.message);
          navigate('/subscription', { state: { id: addCompanyResponse.data.data.id } });
        } else {
          const errorMesg = addCompanyResponse.data.message;
          if (Array.isArray(errorMesg)) {
            addCompanyResponse.data.message.map(e => {
              toast.error(e);
            })
            setloading(false);
          } else {
            toast.error(errorMesg);
            setloading(false);
          }
        }
      } else {
        HandleHomePageError(navigate, addCompanyResponse.status);
      }
      setloading(false);
    }
  };

  return (
    <Fragment>
      {loading && <Transparentspinner />}
      <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
        <Row className="g-3 pt-3">
          <Col md="6">
            <Label >{DefaultCurrency}</Label>
            <Controller
              name="currency"
              control={control}
              defaultValue={formdata.currency || currencyOptions[0]}
              render={({ field }) => (
                <Typeahead
                  options={currencyOptions}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                />
              )}
            />

          </Col>
          <Col md="6">
            <Label >{DefaultLang}</Label>
            <Controller
              name="language"
              control={control}
              defaultValue={formdata.language || languageOptions[0]}
              render={({ field }) => (
                <Typeahead
                  options={languageOptions}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                />
              )}
            />
          </Col>
        </Row>

        <Row className="g-3 pt-3">
        </Row>

        <div className="text-end">
          <span className="ps-2 spinner-enable">
            <Button className=" btn " type="submit" disabled={loading}>
              Finish
            </Button>
          </span>
        </div>
      </Form>
    </Fragment>
  );
};

export default Formstep4;
