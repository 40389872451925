import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { Search } from 'react-feather';
import { Col, Input } from 'reactstrap';
import CustomizerContext from '../../../_helper/Customizer';
import ListOfMenu from '../RightBar/Bookmark/ListOfMenu';
import EmpltyClass from '../RightBar/Bookmark/EmptyClass';
import CompanyBar from '../CompanyBar';
import { UL } from '../../../AbstractElements';
import { useSelector } from 'react-redux';
import { searchcustomer, searchcustomerRole } from '../../../CommonUrl';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { useLocation, useNavigate } from 'react-router-dom';

const Searchbar = () => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const { sidebarResponsive } = useContext(CustomizerContext);
    const [searchValue, setSearchValue] = useState('');
    const [searchIcon, setSearchIcon] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const { setIsClose } = useContext(CustomizerContext);
    const [IsOpen, setIsOpen] = useState(false);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [loading, setLoading] = useState(false);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setSearchValue('');
            setSearchResult([]);
            setIsClose(false);
        }
    }, [setIsClose]);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    const addFix = () => {
        setSearchToggle(true);
    };
    const removeFix = useCallback(() => {
        setSearchValue('');
        setSearchToggle(false);
        setIsClose(false);
    }, [setIsClose]);

    useEffect(() => {
        if (!IsOpen) {
            setSearchValue('');
            setSearchToggle(false);
            setIsClose(false);
        }
    }, [IsOpen, setIsClose])

    useEffect(() => {
        // Reset the search value and close the dropdown when location changes
        setSearchValue('');
        setSearchToggle(false);
        setIsOpen(false);
        setSearchResult([]);
        setIsClose(false);
    }, [location, setIsClose]);

    const handlesearchedKeywords = (event) => {
        const keywords = event.target.value;
        setSearchValue(keywords);
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(setTimeout(() => {
            debounceKeywords(keywords);
        }, 300)); // Adjust the delay time (in milliseconds) as needed
    }

    const debounceKeywords = async (keyword) => {
        setLoading(true);
        keyword ? addFix() : removeFix();
        setSearchValue(keyword);
        if (keyword) {
            const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? searchcustomer + `?search_value=${keyword}` : searchcustomerRole + `?search_value=${keyword}`);
            if (data.status === true) {
                const getcust = data.data;
                setSearchResult(getcust);
                setLoading(false);
            } else if (data.status >= 400 && data.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (data.status >= 500 && data.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
        }
        setLoading(false);
    };

    return (
        <Fragment>
            <Col className="left-header ">
                <UL attrUL={{ className: `simple-list nav-menus  ${sidebarResponsive ? 'open' : ''}` }} >
                    <div className='d-flex'>
                        <div className=''>
                            {/* {authState.userRole === 1 && */}
                                < CompanyBar />
                            {/* } */}
                        </div>

                        <div className={`input-group search-form ms-md-5 ${IsOpen ? 'open' : ''}`}>
                            <Input type="text" placeholder="Search Customer..."
                                value={searchValue}
                                onChange={handlesearchedKeywords}
                                name='search'
                            />
                            <span className=" mobile-search" >
                                <Search onClick={() => { setIsOpen(!IsOpen); }} />
                            </span>
                            <ListOfMenu
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                searchResult={searchResult}
                                removeFix={removeFix}
                                searchBar={true}
                                searchToggle={searchToggle}
                                loading={loading}
                            />
                            <EmpltyClass searchIcon={searchIcon} search={true} />
                        </div>
                    </div>
                </UL>

            </Col>
        </Fragment >
    );
};
export default Searchbar;