import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { PaymentDueRep, PaymentDueList, Search, Filter, Category, Area, Status, Export, } from '../../Constant';
import { getArea, getAreaRole, getCategoryRole, getCollectionDue, getCollectionDueRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { conditionalRow } from '../Common/Rowstyle/Rowstyle';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';
import AreaDropdown from '../Common Field/AreaDropdown';

const PaymentDueReport = () => {
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [filters, setFilters] = useState({
        searchKey: params.get('search') || '',
        areaFilter: params.get('area') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
        end_date: params.get('end_date') ? moment(params.get('end_date'), "DD-MM-YYYY").toDate() : '',
        statusFilter:params.get('status') || '',
    });
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { search: filters.search, area: filters.area },
    });
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([filters.start_date, filters.end_date]);
    const [startDate, endDate] = dateRange
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const formatEndDate = filters.end_date ? moment(filters.end_date).format("DD-MM-YYYY") : '';

    const statuscheck = [
        { value: "active", name: "Active" },
        { value: "inactive", name: "Inactive" }
    ];

    const PaymentDueColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '80px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            center: false,
            width: '120px',
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}>
                    {row.Status.charAt(0).toUpperCase() + row.Status.slice(1)}
                </span>
            ),
        },
        {
            name: <H4>Name</H4>,
            id: 'customer_name',
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Mobile No</H4>,
            id: 'mobile',
            selector: row => row.Mobile,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Address</H4>,
            id: 'customer_address',
            selector: row => row.Address,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Area</H4>,
            id: 'area_name',
            selector: row => row.Area,
            sortable: true,
            center: false,
        },
        {
            name: <H4>Balance Due</H4>,
            id: 'order_balance',
            selector: row => row.Balance,
            sortable: true,
            center: false,
            width: '120px'
        },
        {
            name: <H4>No.of Invoices</H4>,
            id: 'invoice_count',
            selector: row => row.InvoiceNo,
            sortable: true,
            center: true,
        }
    ];

    useEffect(() => {
        fetchPayDue(1);
    }, []);

    useEffect(() => {
        setValue('search', filters.searchKey);
    }, [setValue, filters.searchKey]);

    useEffect(() => {
        if (filters.statusFilter) {
            const selectedStatus = statuscheck.find((status) => status.value === filters.statusFilter);
            if (selectedStatus) {
                setValue('status', selectedStatus.value.toString());
            }
        }
    }, [setValue, filters.statusFilter, statuscheck]);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/payment-due?search=${filters.searchKey}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.areaFilter}&status=${filters.statusFilter}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/payment-due?search=${filters.searchKey}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.areaFilter}&status=${filters.statusFilter}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handlePageChange = page => {
        fetchPayDue(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchPayDue(page, newPerPage);
        setPerPage(newPerPage);
    };

    const onSubmit = async (data) => {
        const areaId = data.area;
        const status = data.status;
        const search = data.search;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const queryParams = new URLSearchParams({
            search: search,
            start_date,
            end_date,
            area: areaId,
            status: status,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/payment-due?${queryParams}`);
        setFilters({
            search,
            start_date,
            end_date,
            areaId,
            status
        });
        fetchPayDue(currentPage, perPage, search, start_date, end_date, areaId, status);
    };

    const fetchPayDue = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionDue +
            `?page=${page}&page_length=${size}&search=${filters.searchKey}&start_date=${formatStartDate}
            &end_date=${formatEndDate}&area_id=${filters.areaFilter}&status=${filters.statusFilter}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            getCollectionDueRole + `?page=${page}&page_length=${size}&search=${filters.searchKey}
            &start_date=${formatStartDate}&end_date=${formatEndDate}&area_id=${filters.areaFilter}
            &status=${filters.statusFilter}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`)
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valuePackages = response.data.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    SubId: valuePackages[e].subscriber_id,
                    Name: valuePackages[e].customer_name,
                    Mobile: valuePackages[e].mobile,
                    Address: valuePackages[e].customer_address,
                    Area: valuePackages[e].area_name,
                    Balance: <span style={{ fontWeight: 'bold', color: '#534686' }}>{authState.currency} {valuePackages[e].order_balance}</span>,
                    InvoiceNo: valuePackages[e].invoice_count,
                    Status: valuePackages[e].customer_status,
                    customer_id: valuePackages[e].customer_id,
                }
            }
            ))
            setTotalRows(response.data.total);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportDueExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionDue +
            `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&area_id=${filters?.areaFilter ?? ''}&status=${filters?.statusFilter ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getCollectionDueRole + `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&area_id=${filters?.areaFilter ?? ''}&status=${filters?.statusFilter ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }) => ({ Subscriber_id: subscriber_id, Status: customer_status, Name: customer_name, Route_No: customer_address, Area: area_name, Balance: order_balance, Number_of_Invoice: invoice_count }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Collection Due Data');
            XLSX.writeFile(wb, 'collection_due_data.xlsx');
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportDuePdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionDue +
            `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&area_id=${filters?.areaFilter ?? ''}&status=${filters?.statusFilter ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getCollectionDueRole + `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&area_id=${filters?.areaFilter ?? ''}&status=${filters?.statusFilter ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }, index) => ({ Sl: index + 1, subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber id", "Status", "Name", "Route No", "Area", "Balance", "Number of Invoice"];
            const rows = dataToExport.map(({ subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }, index) => [index + 1, subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count]);
            doc.autoTable({
                head: [columns],
                body: rows,
                columnStyles: {
                    0: { cellWidth: 11 },
                    4: { cellWidth: 30 },
                },
                styles: { fontSize: 10 },
                headStyles: { fillColor: [100, 150, 200] },
            });
            doc.save("collection_due_data.pdf");
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Row>
                <Breadcrumbs mainTitle={PaymentDueRep} parent="Reports" title={PaymentDueRep} />
            </Row>
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{PaymentDueList}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportDueExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportDuePdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <AreaDropdown
                                authState={authState}
                                registerName="area"
                                selectedAreaId={filters.areaFilter}
                                register={register}
                                setValue={setValue}
                            />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"   {...register('status')}>
                                <option value="">{Status}</option>
                                {statuscheck.map((c, index) => (
                                    <option key={index} value={c.value}>{c.name}</option>
                                ))}
                            </select>
                        </Col>

                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PaymentDueColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PaymentDueReport;