import React, { useState } from 'react'
import { ImportModalNote, Save } from '../../../Constant'
import { CardHeader, Col, Form, Row } from 'reactstrap'
import { useForm } from "react-hook-form"
import { Btn } from '../../../AbstractElements'
import { postClientUrlWithToken } from '../../../Utils/restUtils'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from '../Loader/Transparentspinner'
import { postCustomerImport, postCustomerImportRole } from '../../../CommonUrl'


const schema = yup.object().shape({
    excelFile: yup
        .mixed().required()
        .test('fileType', 'Please upload an Excel file (.xls or .xlsx)', (value) => {
            if (!value) return true;
            return ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value[0]?.type);
        }),
});

const ImportPopUp = ({ setModal, sampleImportApi, imporTitle, tablerenderFunc, currentPage, perPage }) => {
    const authState = useSelector(state => state.auth);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const form = new FormData();
    const [spinnerTrans, setspinnerTrans] = useState(false);

    const submit = async (data) => {
        setspinnerTrans(true);
        form.append("file", data.excelFile[0]);
        const addCustomerResponse = await postClientUrlWithToken(
            authState.apiUrl, authState.userRole === 1 ? postCustomerImport : postCustomerImportRole, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        if (addCustomerResponse.status === 200) {
            if (addCustomerResponse.data.status === true) {
                toast.success(addCustomerResponse.data.message);
                setModal(false);
                setspinnerTrans(false);
                tablerenderFunc(currentPage, perPage);
            } else {
                toast.error(addCustomerResponse.data.message)
                setspinnerTrans(false);
            }
        } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    return (
        <>
            {spinnerTrans && <Transparentspinner />}

            <Form id="form1" onSubmit={handleSubmit(submit)}>
                <Row className="p-2">
                    <Col md={12} lg={12}>
                        <CardHeader className="">
                            <h5 style={{ color: '#534686' }}>{imporTitle}</h5>
                        </CardHeader>
                        <input type="file" {...register('excelFile', { required: true })} accept='.xls,.xlsx'
                            style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', width: '100%' }}
                        />
                        <div className='d-flex justify-content-between m-2'>
                            {errors.excelFile ? (<span style={{ color: 'red' }}>Please upload an Excel file (.xls or .xlsx)</span>) : (
                                <span></span>
                            )}
                            <span> <i className="fa fa-cloud-download" style={{ color: '#534686' }}></i> <a href={sampleImportApi} ><b>Sample file</b></a></span>
                        </div>
                    </Col>
                </Row>
                <Row className="ps-2  mb-3">
                    <Col md={12} lg={12} >
                        <span className='text-secondary'>
                            <b>Note :</b> {ImportModalNote}
                        </span>
                    </Col>
                    <span className='mt-2'>
                        <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">
                            <span>
                                {Save}
                            </span>
                        </Btn>
                    </span>
                </Row>
            </Form>
        </>
    )
}

export default ImportPopUp